<template>
<div class="d-inline-flex p-2">
  <div class="jumbotron min-vw-50 p-3 justify-content-center">
    <h1>Log in to Feedbackker</h1><br>
    <div class="form-group">
      <label for="InputEmail">Email address</label>
      <input type="email" class="form-control" id="InputEmail" v-model="user.email" aria-describedby="emailHelp">
      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    </div>
    <div class="form-group">
      <label for="InputPassword">Password</label>
      <input type="password" class="form-control" id="InputPassword" v-model="user.password">
    </div>
    <div class="form-group form-check">
      <input type="checkbox" value="true" class="form-check-input" id="Check" v-model="user.remember_me">
      <label class="form-check-label" for="Check">Remember Me?</label>
    </div>
    <button type="submit" @click="login" class="btn btn-primary">Submit</button> <br> <br>
    <router-link to="/forgot_password">Forgot Password?</router-link>
  </div>
</div>
</template>

<script>
export default {
  name: 'LoginForm',
  props: {
    msg: String
  },
  data: () => ({
    user: {
      email: '',
      password: '',
      remember_me: false
    }
  }),
  methods: {
    login () {
      const userData = {
        email: this.user.email,
        password: this.user.password,
        remember_me: this.user.remember_me
      }
      this.$store.dispatch('currentUser/loginUser', userData)
    }
  }
}
</script>
